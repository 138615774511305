import { Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BaseForm } from '@app/modules/forms/base.form';
import { TranslateModule } from '@ngx-translate/core';
import { VideoError } from '@videos/errors/video-error';
import { VideoPreviewService } from '@videos/services';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, switchMap, tap, throttleTime } from 'rxjs/operators';
import VideoPrivateError from '../../errors/video-private.error';
import { VideoMetadata } from '../../services/types';
import VideoPreviewComponent from '../video-preview/video-preview.component';

@Component({
    selector: 'app-video-lookup',
    templateUrl: './video-lookup.template.html',
    standalone: true,
    imports: [VideoPreviewComponent, TranslateModule, FormsModule, MatFormFieldModule, MatInputModule],
})
export default class VideoLookupComponent extends BaseForm {
    @Input()
    set videoUrl(videoUrl: string | undefined) {
        this._videoUrl = videoUrl;
        // If a videoUrl has been entered emit from the URLChanges observable which is throttled to reduce API calls
        if (videoUrl && videoUrl !== this.videoMetadata?.url) {
            this.URLChanges.next(videoUrl);
            // if there's existing metadata and the field has been cleared then we need to clear the metadata since the user has indicated they don't want a video
        } else if (this.videoMetadata) {
            this.clearVideoMetadata();
        }
    }
    get videoUrl(): string | undefined {
        return this._videoUrl;
    }

    /**
     * emits whenever the video metadata has changed
     */
    @Output() metaChange = new EventEmitter<VideoMetadata>();

    /**
     * Video Metadata can be provided to the lookup component if it has already been retrieved
     */
    @Input()
    set videoMetadata(videoMetadata: VideoMetadata | undefined) {
        this._videoMetadata = videoMetadata;
        this._videoUrl = this._videoMetadata?.url;
    }
    get videoMetadata(): VideoMetadata | undefined {
        return this._videoMetadata;
    }

    isLoading = false;

    private URLChanges = new Subject<string>();
    private _videoUrl?: string;
    private _videoMetadata?: VideoMetadata;

    /**
     * The form control for the video URL
     */
    get videoInput(): AbstractControl | undefined {
        return this.form?.controls['video'];
    }

    constructor(protected previewService: VideoPreviewService) {
        super();
        this.URLChanges.pipe(
            takeUntilDestroyed(),
            // Whenever the URL changes (from typing) we clear the existing metadata and start loading
            tap(() => {
                this.isLoading = true;
                this.clearVideoMetadata();
            }),
            // throttleTime means that we will only take emissions from the source observable (URLChanges) when there's been 250ms since the last emission
            // This ensures we don't send too many API requests if the user is in the middle of typing. Trailing ensures that we always send the very last emission regardless
            throttleTime(250, undefined, { trailing: true }),
            switchMap((url) =>
                this.previewService.lookupByUrl(url).pipe(catchError((error) => this.handleMetadataError(error)))
            ),
            filter((videoMetadata) => videoMetadata !== undefined) // If the service errored, videoMetadata is undefined so we don't need to set anything
        ).subscribe((videoMetadata) => this.setVideoMetadata(videoMetadata));
    }

    /**
     * Reset the form and metadata state
     */
    private clearVideoMetadata(): void {
        this._videoMetadata = undefined;
        this.metaChange.emit(this.videoMetadata);
        this.videoInput?.setErrors(null);
    }

    /**
     * There was an error retrieving metadata
     */
    private handleMetadataError(error: unknown): Observable<undefined> {
        this.isLoading = false;
        if (error instanceof VideoError) {
            this.form.onSubmit(new SubmitEvent('submit'));
            this.clearVideoMetadata();
            this.videoInput?.setErrors({ [error.code]: true });
            return of(undefined);
        } else {
            throw error;
        }
    }

    /**
     * Called upon successfully retrieving new metadata from the service
     */
    private setVideoMetadata(metadata: VideoMetadata): void {
        if (metadata.privacyStatus === 'private') {
            this.handleMetadataError(new VideoPrivateError('Video is private', metadata.url));
        }
        this.isLoading = false;
        this.videoMetadata = metadata;

        this.metaChange.emit(this.videoMetadata);
    }
}
